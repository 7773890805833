import { Observable } from 'rxjs'
import {
  Qa,
  QaList,
  CreateQa,
  QaBreadcrumbList,
  UpdateQa,
  ReorderQaList
} from '@/entity/qa'
import { api } from '@/data/api'

export class QaRepository {
  public getAll(searchParams: any): Observable<QaList> {
    return api.get<QaList>('/layer/search', searchParams) as Observable<QaList>
  }

  public getDetail(qaId: String): Observable<Qa> {
    return api.get<Qa>('/layer/view/' + qaId) as Observable<Qa>
  }

  public deleteQa(qaId: String): Observable<any> {
    return api.delete<any>('/layer/delete/' + qaId, {}) as Observable<any>
  }

  public getBreadcrumb(qaId: String): Observable<QaBreadcrumbList> {
    return api.get<QaBreadcrumbList>(
      '/layer/prepare_list',
      qaId
    ) as Observable<QaBreadcrumbList>
  }

  public createQa(qa: CreateQa): Observable<CreateQa> {
    return api.put<CreateQa>('/layer/create', qa) as Observable<CreateQa>
  }

  public updateQa(newQa: UpdateQa): Observable<UpdateQa> {
    const {
      title,
      url,
      description,
      tags,
      contact,
      display_finish,
      display_home
    } = newQa
    return api.put<UpdateQa>('/layer/update/' + newQa.id, {
      title,
      url,
      description,
      tags,
      contact,
      display_finish,
      display_home
    }) as Observable<UpdateQa>
  }

  public reorderQaList(newQaList: ReorderQaList): Observable<ReorderQaList> {
    return api.post<ReorderQaList>(
      '/layer/reorder',
      newQaList
    ) as Observable<ReorderQaList>
  }
}
