import { Observable } from 'rxjs'
import { Action, VuexModule } from 'vuex-module-decorators'
import { COMMON_MSG } from '@/common/constant'

export class BaseStore<T> extends VuexModule {
  @Action({ rawError: true })
  execute(observable: Observable<T>): Promise<T | void> {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        this.context.commit('setLoading', true, { root: true })
        const subscription = observable.subscribe({
          next: (data) => {
            resolve(data)
          },
          error: (err) => {
            this.context.commit('setLoading', false, { root: true })
            this.context.commit(
              'setError',
              err.response ? err.response.data : COMMON_MSG.ERROR,
              { root: true }
            )
            reject(err.response)
          },
          complete: () => {
            this.context.commit('setLoading', false, { root: true })
          },
        })
        this.context.commit('pushSubscription', subscription, {
          root: true,
        })
      } else {
        this.context.commit('setErrorNetwork', true, { root: true })
      }
    })
  }
}
