import { User } from '@/entity'
import { AuthRepository } from '@/data'
import { Observable } from 'rxjs'

export class AuthenticationService {
  private authRepo: AuthRepository = new AuthRepository()

  login(model: User): Observable<User> {
    return this.authRepo.login(model)
  }

  register(model: User): Observable<any> {
    return this.authRepo.register(model)
  }
}
