












































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import { ACTIONS_MSG, DEFAULT_MSG } from '@/common/constant'
import MessageList from '@/components/MessageList.vue'
import { postMessageToParent } from '@/common/helper'
import { BotInfo } from '@/entity'
import { ClientStore } from '@/store/ClientStore'

@Component({
  components: { MessageList }
})
export default class ChatBox extends Vue {
  @Prop() data!: any
  @Prop() isPreview!: boolean
  readonly defaultMsg = DEFAULT_MSG
  isMinimized = true
  botInfo: BotInfo = new BotInfo()

  get clientModule(): ClientStore {
    return getModule(ClientStore, this.$store)
  }

  @Watch('data', { immediate: true, deep: true })
  setData() {
    if (this.data) {
      this.botInfo = new BotInfo(this.data)
    }
  }

  onClickHeading() {
    if (this.isMinimized) {
      this.clientModule.countClientAction({
        type: 'CH_OPEN',
        user_id: this.botInfo.id
      })
    }
    this.isMinimized = !this.isMinimized
    if (this.isMinimized) {
      const height = 120
      const width = 120
      postMessageToParent({
        action: ACTIONS_MSG.CHAT_MINIMIZED,
        height,
        width,
        borderRadius: '50%'
      })
    } else {
      const height = 600
      const width = 400
      const borderRadius = '5px 5px 0 0'
      postMessageToParent({
        action: ACTIONS_MSG.CHAT_OPEN,
        height,
        width,
        borderRadius
      })
    }
  }

  onClickMinimizeBtn(event) {
    event.stopPropagation()
    this.isMinimized = true
    const height = 120
    const width = 120
    postMessageToParent({
      action: ACTIONS_MSG.CHAT_MINIMIZED,
      height,
      width,
      borderRadius: '50%'
    })
  }
}
