import { Subject } from 'rxjs'
import { Action, Module, Mutation } from 'vuex-module-decorators'
import { BaseStore } from '@/store/BaseStore'

export class DialogParams {
  icon
  closeButton
  title
  message
  positiveButton
  negativeButton
  subject
  size // 'modal-lg' or 'modal-sm' or null

  constructor(data?: any) {
    this.icon = (data && data.icon) || null
    this.closeButton = (data && data.closeButton) || null
    this.title = (data && data.title) || null
    this.message = (data && data.message) || null
    this.positiveButton = (data && data.positiveButton) || null
    this.negativeButton = (data && data.negativeButton) || null
    this.subject = (data && data.subject) || new Subject()
    this.size = (data && data.size) || null
  }
}

export class DialogResult {
  buttonName
  payload

  constructor(buttonName, payload) {
    this.buttonName = buttonName
    this.payload = payload
  }

  isOk() {
    return this.buttonName === 'ok'
  }

  isCancel() {
    return this.buttonName === 'cancel'
  }
}

@Module({
  name: 'dialog',
  namespaced: true,
})
export class DialogStore extends BaseStore<any> {
  showPopup = new Subject<DialogParams>()
  dialog = new DialogParams()

  get showPopupObservable() {
    return this.showPopup.asObservable()
  }

  @Mutation
  showModal({ icon, message, positiveBtn, negativeBtn, callback }) {
    this.dialog.icon = icon
    this.dialog.closeButton = false
    this.dialog.message = message
    this.dialog.positiveButton = positiveBtn
    this.dialog.negativeButton = negativeBtn
    this.dialog.subject = new Subject()
    this.showPopup.next(this.dialog)
    callback(this.dialog.subject)
  }

  @Action({ rawError: true })
  showError(message) {
    this.showModal({
      icon: 'error',
      message: message,
      positiveBtn: 'OK',
      negativeBtn: null,
      callback: () => {},
    })
  }

  @Action({ rawError: true })
  showSuccess(message) {
    return new Promise((resolve) => {
      this.showModal({
        icon: 'success',
        message: message,
        positiveBtn: 'OK',
        negativeBtn: null,
        callback: (res) => {
          res.subscribe(resolve)
        },
      })
    })
  }

  @Action({ rawError: true })
  showConfirm(message) {
    return new Promise((resolve) => {
      this.showModal({
        icon: 'warning',
        message: message,
        positiveBtn: 'OK',
        negativeBtn: 'キャンセル',
        callback: (res) => {
          res.subscribe(resolve)
        },
      })
    })
  }

  @Action({ rawError: true })
  showSuccessMessage(message) {
    this.showModal({
      icon: 'success',
      message: message,
      positiveBtn: null,
      negativeBtn: null,
      callback: () => {},
    })
  }
}
