import { Action, Module } from 'vuex-module-decorators'
import { InsightService } from '@/service'
import { BaseStore } from '@/store/BaseStore'

@Module({
  name: 'insight',
  namespaced: true
})
export class InsightStore extends BaseStore<any> {
  private insightService: InsightService = new InsightService()

  @Action({ rawError: true })
  getInsightDetail(obj) {
    return this.context.dispatch(
      'execute',
      this.insightService.getInsightDetail(obj)
    )
  }

  @Action({ rawError: true })
  getInsightOverview(obj) {
    return this.context.dispatch(
      'execute',
      this.insightService.getInsightOverview(obj)
    )
  }

  @Action({ rawError: true })
  getInsightTag(obj) {
    return this.context.dispatch(
      'execute',
      this.insightService.getInsightTag(obj)
    )
  }

  @Action({ rawError: true })
  getInsightCSV(obj) {
    return this.context.dispatch(
      'execute',
      this.insightService.getInsightCSV(obj)
    )
  }
}
