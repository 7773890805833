import { UserSettings } from '@/entity'
import { Observable } from 'rxjs'
import { api } from '@/data/api'

export class UserRepository {
  public getUserSettings(userId?: string): Observable<UserSettings> {
    return api.get<UserSettings>(
      '/user/view' + (userId ? '/' + userId : '')
    ) as Observable<UserSettings>
  }

  public putUserSettings(user: any): Observable<any> {
    return api.put<any>('/user/update', user) as Observable<any>
  }
}
