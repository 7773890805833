import { User } from '@/entity'
import { Observable } from 'rxjs'
import { api } from '@/data/api'

export class AuthRepository {
  public login(user: User): Observable<User> {
    return api.post<User>('/auth/signin', user) as Observable<User>
  }

  public register(user: User): Observable<any> {
    return api.post<any>('/auth/signup', user) as Observable<any>
  }
}
