import { Action, Module } from 'vuex-module-decorators'
import { QaService } from '@/service'
import { BaseStore } from '@/store/BaseStore'

@Module({
  name: 'qa',
  namespaced: true,
})
export class QaStore extends BaseStore<any> {
  private qaService: QaService = new QaService()

  @Action({ rawError: true })
  getQaList(paramObject): Promise<any> {
    return new Promise((resolve, reject) => {
      this.context
        .dispatch('execute', this.qaService.getQaList(paramObject.searchParams))
        .then((data) => {
          resolve({
            data: data,
            requestTime: paramObject.requestTime,
          })
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action({ rawError: true })
  getBreadcrumb(qaId: string) {
    return this.context.dispatch('execute', this.qaService.getBreadcrumb(qaId))
  }

  @Action({ rawError: true })
  getQaDetail(qaId: string) {
    return this.context.dispatch('execute', this.qaService.getQaDetail(qaId))
  }

  @Action({ rawError: false })
  createQa(object: any) {
    return this.context.dispatch('execute', this.qaService.createQa(object))
  }

  @Action({ rawError: true })
  delete(qaId: string) {
    return this.context.dispatch('execute', this.qaService.deleteQa(qaId))
  }

  @Action({ rawError: true })
  updateQa(newQa) {
    return this.context.dispatch('execute', this.qaService.updateQa(newQa))
  }

  @Action({ rawError: true })
  reorderQaList(newQaList) {
    return this.context.dispatch(
      'execute',
      this.qaService.reorderQaList(newQaList)
    )
  }
}
