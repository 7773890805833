






import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/store/UserStore'
import ChatBox from '@/components/ChatBox.vue'
import { ACTIONS_MSG, DEF_COLOR } from '@/common/constant'
import { postMessageToParent } from '@/common/helper'

@Component({
  components: { ChatBox }
})
export default class ClientPage extends Vue {
  test_id = '60dd8ed06600f9102a34c626'
  botInfo = { color: '', avatar: '', id: '' }
  isLoaded = false
  requestTime = new Date().getTime()
  defaultAvatar = require('./../../assets/img/user-no-image.png')

  get userModule(): UserStore {
    return getModule(UserStore, this.$store)
  }

  beforeCreate() {
    this.$store.commit('clearSubscription', null, { root: true })
  }

  created() {
    const botId =
      this.$route.params && this.$route.params.botId
        ? this.$route.params.botId
        : this.test_id
    this.requestTime = new Date().getTime()
    const paramObj = { id: botId, requestTime: this.requestTime }
    this.userModule
      .getUserSettings(paramObj)
      .then((value) => {
        // last request
        if (value.requestTime === this.requestTime) {
          this.botInfo = value.data
          this.botInfo.avatar = value.data.avatar
            ? process.env.VUE_APP_BASE_API + value.data.avatar
            : this.defaultAvatar
          setTimeout(() => {
            this.isLoaded = true
          })
        }
      })
      .catch(() => {
        this.botInfo = {
          color: DEF_COLOR,
          avatar: this.defaultAvatar,
          id: ''
        }
        setTimeout(() => {
          this.isLoaded = true
          postMessageToParent({ action: ACTIONS_MSG.LOADED_DATA })
        }, 1000)
      })
  }
}
