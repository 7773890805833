import { Observable } from 'rxjs'
import { api } from '@/data/api'
import { CreateTag, Tag, TagList } from '@/entity/tag'

export class TagRepository {
  public getAll(searchParams): Observable<TagList> {
    return api.get<TagList>('/tag/list', searchParams) as Observable<TagList>
  }

  public create(tag: CreateTag): Observable<CreateTag> {
    return api.post<CreateTag>('/tag/create', tag) as Observable<CreateTag>
  }

  public getDetail(tagId: String): Observable<Tag> {
    return api.get<Tag>('/tag/view/' + tagId) as Observable<Tag>
  }

  public deleteTag(tagId: String): Observable<any> {
    return api.delete<any>('/tag/delete/' + tagId, {}) as Observable<any>
  }

  public updateTag(object): Observable<CreateTag> {
    return api.put<CreateTag>(
      '/tag/update/' + object.tagId,
      object.model
    ) as Observable<CreateTag>
  }
}
