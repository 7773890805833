import moment from 'moment'

const $ = require('jquery')

export function validEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export function validateUrl(string) {
  if (!string) {
    return true
  }
  const reg = new RegExp(
    "^(https?|ftp|file):\\/\\/[-A-Z0-9+&@#$\\/'%?=~_|!:,.;*()]*[-A-Z0-9+&@#$\\/'%=~_|!*()]*$",
    'i'
  )
  return reg.test(string)
}

export function escapeHTML(text) {
  const replacements = {
    '<': '&lt;',
    '>': '&gt;',
    '&': '&amp;',
    '"': '&quot;'
  }
  return text.replace(/[<>&"]/g, (character) => {
    return replacements[character]
  })
}

export function getPropertyValue(obj, field) {
  return obj[field] || false
}

// This function is used to detect the actual image type,
export function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4)
  let header = ''
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16)
  }
  switch (header) {
    case '89504e47':
      return 'image/png'
    case '47494638':
      return 'image/gif'
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg'
    default:
      return fallback
  }
}

export function gotoError() {
  setTimeout(() => {
    const $firstError = $('.is-invalid').first()
    if ($firstError.length > 0) {
      $('html, body').animate(
        {
          scrollTop: $firstError.offset().top - $(window).height() / 3
        },
        70
      )
      $firstError.focus()
      return true
    }
    return false
  }, 200)
}

export function postMessageToParent(data, targetOrigin = '*') {
  window.parent.postMessage(data, targetOrigin)
}

export function matchDayOfWeek(
  precedingStart,
  precedingEnd,
  lastStart,
  lastEnd
) {
  if (
    moment(precedingStart).day() !== moment(lastStart).day() ||
    moment(precedingEnd).day() !== moment(lastEnd).day()
  ) {
    if (moment(precedingStart).toString() == moment(precedingEnd).toString()) {
      return 0
    }
    let diffStart, diffEnd
    if (moment(lastStart).diff(moment(precedingStart), 'weeks', true) > 1) {
      diffStart = moment(precedingStart).day() - moment(lastStart).day()
      diffEnd = moment(precedingEnd).day() - moment(lastEnd).day()
    } else {
      diffStart = Math.round(
        moment(lastStart).diff(moment(precedingStart), 'weeks', true) * 7
      )
      diffEnd = Math.round(
        moment(lastEnd).diff(moment(precedingEnd), 'weeks', true) * 7
      )
    }
    if (Math.abs(diffStart) > Math.abs(diffEnd)) {
      if (
        moment(precedingStart).add(diffEnd, 'days').toString() ==
          moment(lastStart).toString() &&
        moment(precedingEnd).add(diffEnd, 'days').toString() ==
          moment(lastEnd).toString()
      ) {
        return 7 - diffEnd
      }
      return diffEnd
    } else {
      if (
        moment(precedingStart).add(diffStart, 'days').toString() ==
          moment(lastStart).toString() &&
        moment(precedingEnd).add(diffStart, 'days').toString() ==
          moment(lastEnd).toString()
      ) {
        return 7 - diffStart
      }
      return diffStart
    }
  }
  return 0
}

export function hideDropdown(query) {
  $(query).hide()
}

export function closeDropdown(query) {
  $(query).hide()
  $(document).click(function () {
    $(query).hide()
  })
  $(query).click(function (e) {
    e.stopPropagation()
  })
}

export function showDropdown(titleQuery, contentQuery) {
  $(titleQuery).click(function () {
    setTimeout(function () {
      $(contentQuery).show()
    })
  })
}

export function calculateUserDiff(targeted: number, compared: number, num = 0) {
  const result = (targeted / compared - num) * 100
  if (isNaN(result) || result == Infinity) return 0
  else if (result < -100) return -100
  return result
}

export function limitCharacters(str) {
  let newStr = ''
  if (str.length > 10) {
    for (let i = 0; i <= 9; i++) {
      newStr += str[i]
    }
    return newStr + '...'
  }
  return str
}

export function generateRandomColor() {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
