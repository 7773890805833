import { Observable } from 'rxjs'
import { InsightDetail, InsightOverview } from '@/entity/insight'
import { api } from '@/data/api'

export class InsightRepository {
  public detailInsight(detailObject: {
    deep: number
    tag?: string
    layer?: string
    from: string
    to: string
  }): Observable<InsightDetail> {
    return api.get<InsightDetail>(
      '/insight/detail',
      detailObject
    ) as Observable<InsightDetail>
  }

  public overviewInsight(obj: {
    compare_from: string
    compare_to: string
    from: string
    to: string
  }): Observable<InsightOverview> {
    return api.get<InsightOverview>(
      '/insight/overview',
      obj
    ) as Observable<InsightOverview>
  }

  public getInsightTag(obj: any): Observable<any> {
    return api.get<any>('/insight/tag', obj) as Observable<any>
  }

  public getInsightCSV(obj: any): Observable<any> {
    return api.get<any>('/insight/csv', obj) as Observable<any>
  }
}
