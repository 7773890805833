import router from '@/router/index'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import { FORM } from '@/common/constant'
import { getToken } from '@/common/auth-token'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  if (to.path.startsWith('/admin')) {
    // start progress bar
    NProgress.start()
  }

  // set page title
  document.title = to.meta.title || '管理画面'

  // determine whether the user has logged in
  const hasToken = getToken()
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!hasToken) {
      next('/admin/login')
    }
  }
  if (hasToken) {
    switch (to.name) {
      case FORM.LOGIN:
      case FORM.REGISTER:
      case FORM.FORGOT_PASSWORD:
        next('/admin')
        break
      default:
        next()
    }
  }
  next()
  NProgress.done()
})
