var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"chat-box",class:[_vm.isMinimized ? 'minimized' : '']},[_c('div',{staticClass:"chat-box-container"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-heading top-bar",class:_vm.isPreview
            ? 'panel-heading-preview'
            : _vm.isMinimized
            ? ''
            : 'panel-heading-responsive',style:({ '--customColor': _vm.botInfo.color }),on:{"click":_vm.onClickHeading}},[_c('div',{staticClass:"d-flex",class:_vm.isPreview
              ? 'panel-title-preview'
              : !_vm.isMinimized
              ? 'panel-title-preview'
              : 'panel-title'},[_c('span',{staticClass:"avatar"},[_c('img',{class:_vm.isPreview
                  ? 'img-responsive'
                  : _vm.isMinimized
                  ? 'img-max'
                  : 'img-responsive',attrs:{"src":_vm.botInfo.avatar,"alt":""}})]),_c('span',{staticClass:"pl-2 d-flex flex-column w-80"},[_c('span',{staticClass:"font-weight-bold ellipsis-flex",attrs:{"title":_vm.botInfo.title}},[_vm._v(_vm._s(_vm.botInfo.title))]),_c('span',{staticClass:"ellipsis-flex"},[_vm._v(_vm._s(_vm.defaultMsg.CHAT_BOX.TITLE.MSG_2))])])]),_c('div',{staticClass:"panel-control d-flex align-items-center mr-2"},[(!_vm.isMinimized || _vm.isPreview)?_c('i',{staticClass:"fa fa-minus",on:{"click":_vm.onClickMinimizeBtn}}):_vm._e()])]),(_vm.isPreview)?_c('div',{attrs:{"id":"preview_cb"}},[_c('ul',{staticClass:"panel-body msg-list",style:({ '--customColor': _vm.botInfo.color })},[_c('li',[_c('div',{staticClass:"row msg-container msg-receive"},[_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"msg-content receive"},[_c('div',{staticClass:"question-list"},[_c('p',[_vm._v(_vm._s(_vm.botInfo.message_1))])]),_c('div',{staticClass:"button-start"},[_vm._v("新しいチャットを始める")])])])]),_vm._m(0)])])]):_c('MessageList',{attrs:{"botInfo":_vm.botInfo}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row msg-container msg-receive"},[_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"msg-content receive"},[_c('div',{staticClass:"question-list"},[_c('div',{staticClass:"item-list d-list-item preview mt-0 mb-0"},[_c('span',{staticClass:"list-pagination"},[_c('span',{staticClass:"item-content"},[_c('span',[_vm._v("カテゴリー1")])]),_c('span',{staticClass:"item-content"},[_c('span',[_vm._v("カテゴリー2")])]),_c('span',{staticClass:"item-content"},[_c('span',[_vm._v("カテゴリー3")])]),_c('div',{staticClass:"dots-pagination"}),_c('span',{staticClass:"item-content"},[_c('span',[_vm._v("カテゴリーn")])])])])])])])])}]

export { render, staticRenderFns }