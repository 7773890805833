import { Action, Module } from 'vuex-module-decorators'
import { TagService } from '@/service'
import { BaseStore } from '@/store/BaseStore'

@Module({
  name: 'tag',
  namespaced: true,
})
export class TagStore extends BaseStore<any> {
  private tagService: TagService = new TagService()

  @Action({ rawError: true })
  getTagList(paramObject): Promise<any> {
    return new Promise((resolve, reject) => {
      this.context
        .dispatch(
          'execute',
          this.tagService.getTagList(paramObject.searchParams)
        )
        .then((data) => {
          resolve({
            data: data,
            requestTime: paramObject.requestTime,
          })
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action({ rawError: true })
  create(tag) {
    return this.context.dispatch('execute', this.tagService.createNewTag(tag))
  }

  @Action({ rawError: true })
  getTagDetail(tagId: String) {
    return this.context.dispatch('execute', this.tagService.getTagDetail(tagId))
  }

  @Action({ rawError: true })
  update(object) {
    return this.context.dispatch('execute', this.tagService.updateTag(object))
  }

  @Action({ rawError: true })
  delete(tagId: String) {
    return this.context.dispatch('execute', this.tagService.deleteTag(tagId))
  }
}
