import { Action, Module } from 'vuex-module-decorators'
import { BaseStore } from '@/store/BaseStore'
import { ClientService } from '@/service'

@Module({
  name: 'client',
  namespaced: true
})
export class ClientStore extends BaseStore<any> {
  private clientService: ClientService = new ClientService()

  @Action({ rawError: true })
  sendClientMessage(object) {
    return this.context.dispatch(
      'execute',
      this.clientService.sendClientMessage(object)
    )
  }

  @Action({ rawError: true })
  countClientAction(object) {
    return this.context.dispatch(
      'execute',
      this.clientService.countClientAction(object)
    )
  }

  @Action({ rawError: true })
  getLayerFromTag(tagID) {
    return this.context.dispatch(
      'execute',
      this.clientService.getLayerFromTag(tagID)
    )
  }
}
