import { ClientMessage, UserLayer, ClientCount, LayerFromTag } from '@/entity'
import { Observable } from 'rxjs'
import { api } from '@/data/api'

export class ClientRepository {
  public sendMessage(userLayer: UserLayer): Observable<ClientMessage> {
    return api.get<ClientMessage>(
      '/client/message',
      userLayer
    ) as Observable<ClientMessage>
  }

  public countClient(countType: ClientCount): Observable<ClientCount> {
    return api.put<ClientCount>(
      '/client/count',
      countType
    ) as Observable<ClientCount>
  }

  public getLayerFromTag(tagID: string): Observable<LayerFromTag> {
    return api.get<LayerFromTag>(
      '/client/tag/' + tagID
    ) as Observable<LayerFromTag>
  }
}
