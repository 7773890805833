













































































































import { getModule } from 'vuex-module-decorators'
import { Message, MSG_TYPE, QUESTION_TYPE } from '@/entity/message'
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { ClientStore } from '@/store/ClientStore'
import { CLIENT_MESSAGE } from '@/common/constant'

@Component
export default class MessageList extends Vue {
  @Prop() botInfo!: any
  msgType: typeof MSG_TYPE = MSG_TYPE
  questionType: typeof QUESTION_TYPE = QUESTION_TYPE
  messageList: Message[] = []
  isLoading = false
  isFirst = true
  requestTime = new Date().getTime()
  isStartedQA = false
  clientMessage = CLIENT_MESSAGE

  get clientModule(): ClientStore {
    return getModule(ClientStore, this.$store)
  }

  @Watch('botInfo', { immediate: true, deep: true })
  getData() {
    if (this.botInfo.id && this.isFirst) {
      this.isFirst = false
    }
    this.messageList.unshift(
      new Message(MSG_TYPE.RECEIVE, null, [], [], '', this.botInfo.message_1)
    )
  }

  beforeCreate() {
    this.$store.commit('clearSubscription', null, { root: true })
  }

  showLoading(flag) {
    this.isLoading = flag
  }

  showAll(event, message) {
    event.stopPropagation()
    message.showAll = true
  }

  getListQa(
    countStart = false,
    parentTitle = '',
    parentDescription = '',
    parentURL = '',
    parentTags = [],
    parentContact = ''
  ) {
    if (countStart) {
      this.clientModule.countClientAction({
        type: 'CH_USER',
        user_id: this.botInfo.id,
        layer_id: ''
      })
    }
    return this.clientModule
      .sendClientMessage({ layer_id: '', user_id: this.botInfo.id })
      .then((res) => {
        this.showLoading(false)
        const list = res.childrens
        this.messageList.push(
          new Message(
            MSG_TYPE.RECEIVE,
            QUESTION_TYPE.LAYER,
            list,
            parentTags,
            parentTitle,
            parentDescription,
            parentURL,
            parentContact
          )
        )
      })
      .catch(() => {
        this.showLoading(false)
      })
  }

  answerAboutTag(tagId) {
    setTimeout(() => {
      this.clientModule.getLayerFromTag(tagId).then((res) => {
        this.showLoading(false)
        const relatedLayers = res.layer.map((item) => item)
        this.messageList.push(
          new Message(MSG_TYPE.RECEIVE, QUESTION_TYPE.TAG, relatedLayers)
        )
      })
    }, 750)
  }

  answerAboutLayer(layerId, botId = this.botInfo.id) {
    setTimeout(() => {
      this.clientModule
        .sendClientMessage({ layer_id: layerId, user_id: botId })
        .then((res) => {
          this.showLoading(false)
          const {
            title,
            description,
            url,
            id,
            contact,
            display_home,
            display_finish
          } = res.info
          const tags = res.tags.map((tag) => tag)
          const childrens = res.childrens.map((child) => child)
          const displayHome = display_home
          const displayFinish = display_finish
          this.messageList.push(
            new Message(
              MSG_TYPE.RECEIVE,
              QUESTION_TYPE.LAYER,
              childrens,
              tags,
              title,
              description,
              url,
              id,
              contact,
              displayHome,
              displayFinish
            )
          )
        })
        .catch(() => {
          this.showLoading(false)
        })
    }, 750)
  }

  sendMessage(item, questionType) {
    if (item.title) {
      this.messageList.push(
        new Message(MSG_TYPE.SEND, null, [], [], item.title)
      )
    } else {
      this.messageList.push(new Message(MSG_TYPE.SEND, null, [], [], item.name))
    }
    this.showLoading(true)
    switch (questionType) {
      case QUESTION_TYPE.TAG: {
        this.answerAboutTag(item.id)
        break
      }
      case QUESTION_TYPE.LAYER: {
        this.answerAboutLayer(item.id)
        break
      }
      default: {
        break
      }
    }
  }

  clientCommand(command: string, layerID = null) {
    switch (command) {
      case CLIENT_MESSAGE.BEGIN.COMMAND: {
        this.messageList.push(
          new Message(MSG_TYPE.SEND, null, [], [], CLIENT_MESSAGE.BEGIN.MESSAGE)
        )
        this.showLoading(true)
        setTimeout(() => {
          this.getListQa(true)
          return (this.isStartedQA = true)
        }, 750)
        break
      }
      case CLIENT_MESSAGE.GO_HOME.COMMAND: {
        this.messageList.push(
          new Message(
            MSG_TYPE.SEND,
            null,
            [],
            [],
            CLIENT_MESSAGE.GO_HOME.MESSAGE
          )
        )
        this.showLoading(true)
        setTimeout(() => {
          this.getListQa()
        }, 750)
        break
      }
      case CLIENT_MESSAGE.END.COMMAND: {
        this.messageList.push(
          new Message(MSG_TYPE.SEND, null, [], [], CLIENT_MESSAGE.END.MESSAGE)
        )
        this.showLoading(true)
        setTimeout(() => {
          this.messageList.push(
            new Message(
              MSG_TYPE.RECEIVE,
              QUESTION_TYPE.LAYER,
              [],
              [],
              '',
              CLIENT_MESSAGE.END.FEEDBACK
            )
          )
          this.showLoading(false)
        }, 750)
        break
      }
      //Go to Contact page
      default: {
        this.clientModule.countClientAction({
          type: 'CH_CONTACT',
          user_id: this.botInfo.id,
          layer_id: layerID
        })
        break
      }
    }
  }
}
