import { Action, Module } from 'vuex-module-decorators'
import { UserService } from '@/service'
import { BaseStore } from '@/store/BaseStore'

@Module({
  name: 'user',
  namespaced: true,
})
export class UserStore extends BaseStore<any> {
  private userService: UserService = new UserService()

  @Action({ rawError: true })
  getUserSettings(paramObject): Promise<any> {
    return new Promise((resolve, reject) => {
      this.context
        .dispatch('execute', this.userService.getUserSettings(paramObject.id))
        .then((data) => {
          resolve({
            data: data.user,
            requestTime: paramObject.requestTime,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  putUserSettings(model) {
    return this.context.dispatch(
      'execute',
      this.userService.putUserSettings(model)
    )
  }
}
