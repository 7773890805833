import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { getToken, removeToken } from '@/common/auth-token'
const baseUrl = process.env.VUE_APP_BASE_API + '/api/'

const axiosRequestConfiguration: AxiosRequestConfig = {
  baseURL: baseUrl,
  responseType: 'json',
}

const initialization = (): AxiosInstance => {
  const axiosInstance = axios.create(axiosRequestConfiguration)

  /*
      Add default headers, interceptors etc..
  */
  axiosInstance.interceptors.request.use((config) => {
    const userToken = getToken()
    if (userToken) {
      // let each request carry token
      config.headers['Authorization'] = `Bearer ${userToken['accessToken']}`
    }
    return config
  })

  axiosInstance.interceptors.response.use(
    (response) => {
      // Edit response config
      return response
    },
    (error) => {
      const userToken = getToken()
      if (error.response.status === 401 && userToken) {
        localStorage.setItem('chatbot-logout-event', 'logout' + Math.random())
        removeToken()
        location.reload()
        return
      }
      return Promise.reject(error)
    }
  )

  return axiosInstance
}

export default initialization
