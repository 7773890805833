const TOKEN_KEY = 'chatbot_auth_token'

export function getToken() {
  return JSON.parse(localStorage[TOKEN_KEY] || false)
}

export function setToken(token) {
  return localStorage.setItem(TOKEN_KEY, JSON.stringify(token))
}

export function removeToken() {
  return localStorage.removeItem(TOKEN_KEY)
}
