export const DEF_COLOR = '#ff5577'

export const ACTIONS_MSG = {
  CHAT_MINIMIZED: 'ChatMinimized',
  CHAT_CLOSED: 'ChatClosed',
  LOADED_DATA: 'LoadedData',
  CHAT_OPEN: 'ChatOpen'
}

export const FORM = {
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'ForgotPassword',
  REGISTER: 'Register'
}

export const SIDE_MENU = {
  LAYER: '階層一覧',
  INSIGHT: 'インサイト',
  TAGS: 'タグ',
  SETTINGS: '設定'
}

export const DEFAULT_MSG = {
  CHAT_BOX: {
    TITLE: {
      MSG_1: '〇〇がサポートします',
      MSG_2: 'ご質問を選んでください♪'
    },
    MSG_1:
      'こちらではお客様がわからないことにお答えします。面倒な文字入力がなく、クリック操作だけでお客様の疑問や不明点を解消できるので、ご自由にご活用ください。',
    MSG_2: 'ご質問内容をお選びください',
    MSG_3: 'ご利用いただきありがとうございます。知りたい項目を選んでください。'
  },
  NO_QA: {
    MSG_1: '現在「',
    MSG_2:
      '」に関する情報がありません。その他に不明点がありましたら、質問内容をお選びください。'
  }
}

export const COMMON_MSG = {
  SAVED: '保存しました',
  ERROR: 'エラーが発生しました。',
  DELETE_CONFIRM: '削除しますか？',
  DELETED: '削除しました。',
  LOGIN_FAILED: 'ユーザー名またはパスワードが間違っています',
  NO_RESULTS: 'データはありません',
  UPLOAD_FILE_NOTE: '画像ファイル( JPEG,PNG,GIF )10MB以下',
  SAVE_CONFIRM: '並び順を変更してもよろしいですか。'
}

export const ERR_MSG = {
  EMAIL: {
    REQUIRED: '必ず入力してください',
    PATTERN: 'Please provide valid email'
  },
  PASSWORD: {
    REQUIRED: '必ず入力してください',
    MINLENGTH: 'Password should be at least 6 characters long',
    SAME_AS_PASSWORD: 'Passwords should be matched'
  },
  NAME: {
    REQUIRED: '必ず入力してください'
  },
  TAG_NAME: {
    REQUIRED: '必ず入力してください',
    MIN_LENGTH: '2文字以上にしてください',
    MAX_LENGTH: '20文字以下にしてください',
    EXISTED: '既に使用されています'
  },
  QA_TITLE: {
    REQUIRED: '必ず入力してください',
    MIN_LENGTH: '5文字以上にしてください',
    MAX_LENGTH: '40文字以下にしてください'
  },
  QA_DES: {
    REQUIRED: '必ず入力してください',
    MIN_LENGTH: '10文字以上にしてください',
    MAX_LENGTH: '1000文字以下にしてください'
  },
  URL: {
    PATTERN: '有効なURL形式で指定してください'
  },
  IMAGE: {
    MAX_LENGTH: '添付ファイルの容量は10MBを超えています',
    INVALID_TYPE: '「JPEG、GIF、PNGのいずれかファイルのみです」が表示される'
  },
  CHAT_BOX: {
    REQUIRED: '必ず入力してください',
    TITLE: {
      MAX_LENGTH: '100文字以下にしてください'
    },
    MSG: {
      MAX_LENGTH: '1000文字以下にしてください'
    }
  }
}

export const CLIENT_MESSAGE = {
  BEGIN: {
    MESSAGE: '新しいチャットを始める',
    COMMAND: 'begin'
  },
  GO_HOME: {
    MESSAGE: '最初に戻る',
    COMMAND: 'go homepage'
  },
  END: {
    MESSAGE: 'チャットを終了する',
    COMMAND: 'end',
    FEEDBACK:
      'チャットを終了します。\n他に知りたい情報がありましたら、いつでもお気軽にご質問ください。'
  }
}

export const INSIGHT_FIELD = [
  'チャットオープン人数',
  'チャット利用人数',
  'シナリオ内総会話数',
  'CV数'
]

export const COMPARE_TITLE = [
  '今日',
  '昨日',
  '今週',
  '先週',
  '過去７日間',
  '過去28日間',
  '過去30日間',
  '過去90日間',
  '過去12か月間',
  '昨年',
  '今年（1月～今日）',
  'カスタム'
]

export const NO_INSIGHT_MESSAGE = {
  NO_DATA: {
    message:
      '指定した期間はデータ保有期間が切れております。保有期間内での期間指定をお願いします。'
  },
  NO_CHILDREN: {
    message: 'データがありません。'
  }
}
