import { DEF_COLOR, DEFAULT_MSG } from '@/common/constant'

export interface User {
  username: string
  password: string
}

export interface UserSettings {
  id: string
  username?: string
  color?: string
  avatar?: string
}

export class BotInfo implements UserSettings {
  id: string
  username: string
  avatar: string
  color: string
  title?: string
  message_1?: string


  constructor(data?: any) {
    this.id = (data && data.id) || ''
    this.username = (data && data.username) || ''
    this.avatar = (data && data.avatar) || null
    this.color = (data && data.color) || DEF_COLOR
    this.title = (data && data.title) || DEFAULT_MSG.CHAT_BOX.TITLE.MSG_1
    this.message_1 = (data && data.message_1) || DEFAULT_MSG.CHAT_BOX.MSG_1
  }
}
