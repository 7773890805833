import Vue from 'vue'
import Vuex from 'vuex'
import { Subscription } from 'rxjs'
import { getToken } from '@/common/auth-token'
import { DialogStore } from '@/store/DialogStore'
import { AuthStore } from '@/store/AuthStore'
import { UserStore } from '@/store/UserStore'
import { TagStore } from '@/store/TagStore'
import { QaStore } from '@/store/QaStore'
import { ClientStore } from '@/store/ClientStore'
import { InsightStore } from '@/store/InsightStore'
Vue.use(Vuex)

export interface BaseError {
  message: string
  status: number
  errorCodes: []
}

export interface RootState {
  rootUser: any
  loading: boolean
  isShowSidebar: boolean
  isErrorNetwork: boolean
  error: BaseError
  subscriptions: Subscription[]
}

const store = new Vuex.Store<RootState>({
  modules: {
    dialog: DialogStore,
    auth: AuthStore,
    user: UserStore,
    tag: TagStore,
    qa: QaStore,
    client: ClientStore,
    insight: InsightStore
  },
  actions: {},
  state: {
    rootUser: getToken(),
    loading: false,
    isShowSidebar: false,
    isErrorNetwork: false,
    error: { message: '', errorCodes: [], status: 0 },
    subscriptions: []
  },
  getters: {
    getRootUser: (state) => state.rootUser,
    getLoading: (state) => state.loading,
    getError: (state) => state.error,
    getSubscription: (state) => state.subscriptions,
    getShowSidebar: (state) => state.isShowSidebar
  },
  mutations: {
    setRootUser: (state, payload) => {
      state.rootUser = payload
    },
    setLoading: (state, payload) => {
      state.loading = payload
    },
    setError: (state, payload) => {
      state.error = payload
    },
    setErrorNetwork: (state, payload) => {
      state.isErrorNetwork = payload
    },
    setShowSidebar: (state, payload) => {
      state.isShowSidebar = payload
    },
    pushSubscription: (state, subscription) => {
      state.subscriptions.push(subscription)
    },
    clearSubscription: (state) => {
      state.subscriptions.forEach((s) => {
        if (!s.closed) {
          console.log('FORCE unsubscribe')
          s.unsubscribe()
        } else {
          console.log('NO-NEED unsubscribe')
        }
      })
      state.subscriptions.length = 0
    },
    logout: (state) => {
      state.rootUser = null
    }
  }
})

export default store
