import Vue from 'vue'
import VueRouter from 'vue-router'
import { FORM, SIDE_MENU } from '@/common/constant'
import ClientPage from '@/views/client/ClientPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/client'
  },
  {
    path: '/admin',
    component: () => import('../views/admin/AdminPage.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/admin/layout/SiteLayout.vue'),
        name: 'Site Layout',
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            name: 'Dashboard',
            component: () => import('../views/admin/Dashboard.vue')
          },
          {
            path: 'tags',
            name: 'Tags',
            component: () => import('../views/admin/Tags.vue'),
            meta: {
              title: SIDE_MENU.TAGS
            }
          },
          {
            path: 'settings',
            name: 'Settings',
            component: () => import('../views/admin/Settings.vue'),
            meta: {
              title: SIDE_MENU.SETTINGS
            }
          },
          {
            path: 'layer',
            component: () => import('../views/admin/QAs.vue'),
            name: 'List Layer',
            children: [
              {
                path: ':layerId',
                component: () => import('../views/admin/QAs.vue')
              }
            ]
          },
          {
            path: 'insight',
            component: () => import('../views/admin/Insight.vue'),
            name: 'Insight'
          },
        ]
      },
      {
        path: 'login',
        name: FORM.LOGIN,
        component: () => import('../views/admin/Login.vue')
      },
      {
        path: 'forgot_password',
        name: FORM.FORGOT_PASSWORD,
        component: () => import('../views/admin/Login.vue')
      },
      {
        path: 'register',
        name: FORM.REGISTER,
        component: () => import('../views/admin/Login.vue')
      }
    ]
  },
  {
    path: '/client',
    component: ClientPage,
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: ':botId',
        component: ClientPage
      }
    ]
  },
  {
    path: '/admin/*',
    redirect: '/admin'
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
